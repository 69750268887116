import { render, staticRenderFns } from "./generate-codes.vue?vue&type=template&id=46478b03&scoped=true&"
import script from "./generate-codes.vue?vue&type=script&lang=js&"
export * from "./generate-codes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46478b03",
  null
  
)

export default component.exports