<template>
  <div class="generate-codes">
    <div class="container padding-top-90">
      <div class="row mb-3">
        <div class="col-6">
          <form name="form" class="form form-horizontal" @submit.prevent="createCodes">
            <fieldset :disabled="creating">
              <div class="row">
                <div class="col-6">
                  <div class="form-group">
                    <input type="number" name="numberOfCodes" v-model="numberOfCodes" class="form-control" placeholder="Antal koder">
                  </div>
                </div>
                <div class="col-6">
                  <button type="submit" class="btn btn-success">Skapa</button>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
        <div class="col-6 text-right">
          <button type="button" class="btn btn-secondary">Ladda ner CSV</button>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <strong>Antal koder:</strong> {{ codes.length }}
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>Kod</th>
                <th>Använd?</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="codes.length == 0">
                <td colspan="3" class="text-center">Inga frågor inskickade. :(</td>
              </tr>
              <tr v-for="code in codes" :key="code.id">
                <td>{{ code.id }}</td>
                <td>{{ translator[code.used.toString()] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { firebase } from "@/initializers/firebase"
  import { range, sampleSize } from "lodash"

  export default {
    name: "home",
    beforeRouteEnter(to, from, next) {
      if(to.query.showme) {
        next()
      }
    },
    data() {
      return {
        creating: false,
        codes: [],
        numberOfCodes: null,
        translator: {
          "true": "Ja",
          "false": "Nej"
        }
      }
    },
    methods: {
      async generateCode() {
        return new Promise((resolve) => {
          (async () => {
            let characters = [
              "A", "B", "C", "D", "E", "F", "G", "H", "J", "K", "L", "M", "N", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
              "a", "b", "c", "d", "e", "f", "g", "h", "j", "k", "m", "n", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z",
              0, 2, 3, 4, 5, 6, 7, 8, 9
            ]

            let codeString = sampleSize(characters, 8).join("")

            let codeRef = await firebase.firestore().collection(`codes`).doc(codeString)
            let code = await codeRef.get()

            if(code.exists) {
              await this.generateCode()
            } else {
              resolve(codeString)
            }
          })()
        })
      },
      async createCodes() {
        this.creating = true
        let batch = firebase.firestore().batch()
        let codesRange = range(Number(this.numberOfCodes))

        for(let index of codesRange) {
          let code = await this.generateCode(index)
          let codeRef = await firebase.firestore().collection(`codes`).doc(code)

          batch.set(codeRef, {
            used: false,
            currentUser: ""
          })
        }

        await batch.commit()

        this.creating = false
      }
    },
    async mounted() {
      await this.$bind("codes", firebase.firestore().collection(`codes`))
      this.loading = false
    }
  }
</script>

<style lang="scss" scoped>
  
</style>